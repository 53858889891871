import React, { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Button, Box, Typography, Grid } from '@mui/material';
import Compressor from 'compressorjs';

const HomePage = () => {
  const navigate = useNavigate();
  const [uploadResult, setUploadResult] = useState(null);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [navigate]);

    
//   const [paths, setPaths] = useState({});

//   useEffect(() => {
//     fetch('/get_paths')
//       .then(response => response.json())
//       .then(data => setPaths(data));
//   }, []);

//   const handleButtonClick = () => {
//     window.open(`/${Object.keys(paths)[0]}`, '_blank', 'noopener,noreferrer');
//   };

//   const handleButtonClick2 = () => {
//     window.open(`/${Object.keys(paths)[1]}`, '_blank', 'noopener,noreferrer');
//   };   
//   const handleButtonClick3 = () => {
//     window.open(`/${Object.keys(paths)[2]}`, '_blank', 'noopener,noreferrer');
//   };

//   const handleButtonClick4 = () => {
//     window.open(`/${Object.keys(paths)[3]}`, '_blank', 'noopener,noreferrer');
//   };   
//   const handleButtonClick5 = () => {
//     window.open(`/${Object.keys(paths)[4]}`, '_blank', 'noopener,noreferrer');
//   }; 
//   const handleButtonClick6 = () => {
//     window.open(`/${Object.keys(paths)[5]}`, '_blank', 'noopener,noreferrer');
//   }; 
//   const handleButtonClick7 = () => {
//     window.open(`/${Object.keys(paths)[6]}`, '_blank', 'noopener,noreferrer');
//   }; 
    
  const handleButtonClick = () => {
    window.open('https://stardatemine.online:8031', '_blank', 'noopener,noreferrer');
  };

  const handleButtonClick2 = () => {
    window.open('https://stardatemine.online:8036', '_blank', 'noopener,noreferrer');
  };
    
  const handleButtonClick3 = () => {
    window.open('https://stardatemine.online:8038', '_blank', 'noopener,noreferrer');
  };

  const handleButtonClick4 = () => {
    window.open('http://119.3.254.20:8082', '_blank', 'noopener,noreferrer');
  };
    
  const handleButtonClick5 = () => {
    window.open('http://119.3.254.20:8188', '_blank', 'noopener,noreferrer');
  };  
    
   const handleButtonClick6 = () => {
    window.open('http://119.3.254.20:8189', '_blank', 'noopener,noreferrer');
  }; 
    
   const handleButtonClick7 = () => {
    window.open('http://119.3.254.20:8190', '_blank', 'noopener,noreferrer');
  };     
 
//    const handleButtonClick8 = () => {
//     window.open('http://119.3.254.20:8083', '_blank', 'noopener,noreferrer');
//   };   
   const handleButtonClick8 = () => {
    window.open('https://stardatemine.online:8182', '_blank', 'noopener,noreferrer');
  }; 
   const handleButtonClick9 = () => {
    window.open('http://119.3.254.20:8092', '_blank', 'noopener,noreferrer');
  }; 
    
   const handleButtonClick10 = () => {
    window.open('https://stardatemine.online:8806', '_blank', 'noopener,noreferrer');
  }; 
    
    
  return (
    <Box display="flex" flexDirection="column" alignItems="center" height="100vh" justifyContent="flex-start" pt={8}>
      <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
        <Typography variant="h4" component="div" sx={{ marginBottom: '10px', fontSize: '2rem' }}>
          App Store
        </Typography>
        <Typography variant="body1" component="div" sx={{ fontSize: '1rem', color: 'gray' }}>
          AI数据挖掘平台-刘午牛(Neo)-出品
        </Typography>
      </Box>

      <Grid container spacing={4} justifyContent="center" alignItems="flex-start" sx={{ width: '80%' }}>
        {/* 数据应用区域 */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" component="div" sx={{ marginBottom: '10px', fontSize: '1.5rem' }}>
            数据挖掘
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                component={RouterLink}
                to="/dashboard1"
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                燃气业务数据看板
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                component={RouterLink}
                to="/ReportList"
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                各类数据分析报告
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                component={RouterLink}
                to="/grafa"
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                设备数据监控
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* AI应用区域 */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" component="div" sx={{ marginBottom: '10px', fontSize: '1.5rem' }}>
            AI应用
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                component={RouterLink}
                to="/dashboard2"
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                AI地址处理
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleButtonClick}
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                私有化大模型—小葫芦
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleButtonClick2}
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                智能数据查询—ChatBI
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleButtonClick3}
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                智能图表分析—DC Analyzer
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleButtonClick8}
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                智研报告助手—AI智能体
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={handleButtonClick9}
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                文档生成助手—AI智能体
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={handleButtonClick10}
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                财报分析助手—RAG智能体
              </Button>
            </Grid>

          </Grid>
        </Grid>

        {/* 平台区域 */}
        <Grid item xs={12} md={12}>
          <Typography variant="h5" component="div" sx={{ marginBottom: '10px', fontSize: '1.5rem' }}>
            AI开发平台
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={handleButtonClick5}
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                jupyter lab(应用开发)
              </Button>  
            </Grid> 

            <Grid item xs={6}>
              <Button
                onClick={handleButtonClick6}
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                jupyter lab(模型部署)
              </Button>  
            </Grid> 

            <Grid item xs={6}>
              <Button
                onClick={handleButtonClick7}
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                ComfyUI flux1.0
              </Button>  
            </Grid> 

            <Grid item xs={6}>
              <Button
                onClick={handleButtonClick4}
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                Agent studio
              </Button>  
            </Grid>
            <Grid item xs={6}>
              <Button
                component={RouterLink}
                to="/upload-download"
                variant="contained"
                fullWidth
                sx={{
                  padding: '10px 40px',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: 'lightblue',
                    color: 'black',
                  },
                }}
              >
                图片上传与下载
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomePage;
